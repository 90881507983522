// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import IframeResizer from "iframe-resizer-react";

// style imports
import "./reserva.scss";

// svg imports

// components imports
import Layout from "../../../components/Layout/Layout.js";
import Seo from "../../../components/Seo/Seo.js";

// page
export default function IndexPage() {
	const { t } = useTranslation();
	const { language } = useI18next();

	return (
		<Layout local="cafe">
			<Seo lang={language} title={t("Reserva")} />
			<section id="coverManager" className="reservaForm">
				<script
					type="text/javascript"
					src="https://www.covermanager.com/js/iframeResizer.min.js"
				></script>
				{language === "es" ? (
					<IframeResizer
						id="restaurante-bossa-estepona"
						title="Reservas"
						src="https://www.covermanager.com/reserve/module_restaurant/restaurante-bossa-estepona/spanish"
						frameBorder="0"
						height="300"
						width="100%"
						heightCalculationMethod="max"
						scrolling="true"
					/>
				) : (
					<IframeResizer
						id="restaurante-bossa-estepona"
						title="Reservas"
						src="https://www.covermanager.com/reserve/module_restaurant/restaurante-bossa-estepona/english"
						frameBorder="0"
						height="300"
						width="100%"
						heightCalculationMethod="max"
						scrolling="true"
					/>
				)}
			</section>
		</Layout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
